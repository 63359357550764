import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _faebf42e = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _1314a9ba = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _0a9d03fc = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _2c216a6e = () => interopDefault(import('../pages/member.vue' /* webpackChunkName: "pages/member" */))
const _61c90f64 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _68a1c482 = () => interopDefault(import('../pages/realtime.vue' /* webpackChunkName: "pages/realtime" */))
const _a4f07002 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _79c13a11 = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _7e1640ce = () => interopDefault(import('../pages/topic/index.vue' /* webpackChunkName: "pages/topic/index" */))
const _d665efa6 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _d7304dcc = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _95f807f0 = () => interopDefault(import('../pages/brands/meridank.vue' /* webpackChunkName: "pages/brands/meridank" */))
const _27f0058e = () => interopDefault(import('../pages/brands/sinyi.vue' /* webpackChunkName: "pages/brands/sinyi" */))
const _59bf5232 = () => interopDefault(import('../pages/amp/article/_id.vue' /* webpackChunkName: "pages/amp/article/_id" */))
const _f89d3306 = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _3b93c015 = () => interopDefault(import('../pages/search/_q.vue' /* webpackChunkName: "pages/search/_q" */))
const _3be3c4bb = () => interopDefault(import('../pages/series-sort/_id.vue' /* webpackChunkName: "pages/series-sort/_id" */))
const _743a1eb6 = () => interopDefault(import('../pages/topic/_id.vue' /* webpackChunkName: "pages/topic/_id" */))
const _4a6d5a02 = () => interopDefault(import('../pages/video/_id.vue' /* webpackChunkName: "pages/video/_id" */))
const _57cd9f00 = () => interopDefault(import('../pages/author/_role/_name.vue' /* webpackChunkName: "pages/author/_role/_name" */))
const _c8b88736 = () => interopDefault(import('../pages/category/_mainCategory/_subCategory.vue' /* webpackChunkName: "pages/category/_mainCategory/_subCategory" */))
const _bb7c3ea4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _faebf42e,
    meta: {},
    alias: ["/amp/about"],
    name: "about"
  }, {
    path: "/brands",
    component: _1314a9ba,
    meta: {},
    alias: ["/amp/brands"],
    name: "brands"
  }, {
    path: "/contact",
    component: _0a9d03fc,
    meta: {},
    alias: ["/amp/contact"],
    name: "contact"
  }, {
    path: "/member",
    component: _2c216a6e,
    meta: {},
    alias: ["/amp/member"],
    name: "member"
  }, {
    path: "/privacy",
    component: _61c90f64,
    meta: {},
    alias: ["/amp/privacy"],
    name: "privacy"
  }, {
    path: "/category/%E6%9C%80%E6%96%B0",
    component: _68a1c482,
    meta: {},
    alias: ["/amp/category/%E6%9C%80%E6%96%B0"],
    name: "realtime"
  }, {
    path: "/search",
    component: _a4f07002,
    meta: {},
    alias: ["/amp/search"],
    name: "search"
  }, {
    path: "/sitemap",
    component: _79c13a11,
    meta: {},
    alias: ["/amp/sitemap"],
    name: "sitemap"
  }, {
    path: "/topic",
    component: _7e1640ce,
    meta: {},
    alias: ["/amp/topic"],
    name: "topic"
  }, {
    path: "/unsubscribe",
    component: _d665efa6,
    meta: {},
    alias: ["/amp/unsubscribe"],
    name: "unsubscribe"
  }, {
    path: "/video",
    component: _d7304dcc,
    meta: {},
    alias: ["/amp/video"],
    name: "video"
  }, {
    path: "/brands/meridank",
    component: _95f807f0,
    meta: {},
    alias: ["/amp/brands/meridank"],
    name: "brands-meridank"
  }, {
    path: "/brands/sinyi",
    component: _27f0058e,
    meta: {},
    alias: ["/amp/brands/sinyi"],
    name: "brands-sinyi"
  }, {
    path: "/amp/article/:id?",
    component: _59bf5232,
    meta: {"amp":true},
    alias: [],
    name: "amp-article-id"
  }, {
    path: "/article/:id?",
    component: _f89d3306,
    meta: {},
    alias: ["/amp/article/:id?"],
    name: "article-id"
  }, {
    path: "/search/:q",
    component: _3b93c015,
    meta: {},
    alias: ["/amp/search/:q"],
    name: "search-q"
  }, {
    path: "/series-sort/:id?",
    component: _3be3c4bb,
    meta: {},
    alias: ["/amp/series-sort/:id?"],
    name: "series-sort-id"
  }, {
    path: "/topic/:id",
    component: _743a1eb6,
    meta: {},
    alias: ["/amp/topic/:id"],
    name: "topic-id"
  }, {
    path: "/video/:id",
    component: _4a6d5a02,
    meta: {},
    alias: ["/amp/video/:id"],
    name: "video-id"
  }, {
    path: "/author/:role?/:name?",
    component: _57cd9f00,
    meta: {},
    alias: ["/amp/author/:role?/:name?"],
    name: "author-role-name"
  }, {
    path: "/category/:mainCategory?/:subCategory?",
    component: _c8b88736,
    meta: {},
    alias: ["/amp/category/:mainCategory?/:subCategory?"],
    name: "category-mainCategory-subCategory"
  }, {
    path: "/",
    component: _bb7c3ea4,
    meta: {},
    alias: ["/amp/"],
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
